@import '_reset';

@import '_variables';

@import 'layout';


html,
body {
    position: relative;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: $base-line-height;
    background-color: $base-800;
    color: $on-base;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url('../assets/images/app-background.jpg');
    background-position: center;
    background-size: contain;

    @include device(xs, s) {
        font-size: 14px;
    }
    @include device(m, l, xl) {
        font-size: 16px;
    }
    @include device(xxl, xxxl) {
        font-size: 18px;
    }
}

::selection {
    color: $on-primary;
    background: $primary-100;
}

*,
*::before,
*::after {
    font: inherit;
	position: relative;
  	box-sizing: border-box;
}