@import 'src/styles/variables';

.home-wrapper {
    .hero-section {
        position: relative;
        width: auto;
        min-width: 100%;
        height: auto;
        min-height: 100vh;
        overflow: hidden;
        .hero-video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%; 
            max-width: 100%;
            min-height: 100%;
            width: auto; 
            height: auto; 
            z-index: 1;
            transform: translate(-50%, -50%);
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
        .sign-up-wrapper,.landing-page-logo, .apply-wrapper {
            position: absolute;
            z-index: 3;
            color: $on-base; 
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .sign-up-wrapper {
            height: 58px;
            width: 200px;
            top: 78%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../../assets/images/signup-unselected.svg');
            &:hover {
                background-image: url('../../assets/images/signup.svg');
            }
        }
        .apply-wrapper {
            height: 58px;
            width: 200px;
            top: 75%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../../assets/images/apply-unselected.svg');
            &:hover {
                background-image: url('../../assets/images/apply.svg');
            }
        }
        .landing-page-logo {
            height: 13.6em;
            width: 13.6em;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../../assets/images/LANING_PAGE_LOCKUP.svg');
        }
        @include device(m){
            .landing-page-logo{
                top: 65%;
            }
        }
        @include device(xs){
            .landing-page-logo{
                top: 65%;
            }
        }
        @include device(l){
            .landing-page-logo{
                top: 68%;
            }
        }
        @include device(l, xl, xxl, xxxl){
            .landing-page-logo {
                height: 17em;
                width: 17em;
            }
        }
    }

    .home-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            'content'
            'team-image';


        .content-wrapper {
            grid-area: content;
            padding: 1.62em;
            text-align: center;

            .title {
                font-size: 1.25em;
                font-weight: 900;
                line-height: 1;
                margin-bottom: 1em;
                color: $on-base;
                text-transform: uppercase;
            }
            .content {
                font-size: 1.1em;
                font-weight: 500;
                line-height: 1.2;
                color: $on-base;
                &:not(:last-of-type) {
                    margin-bottom: 2em !important;
                }
            }
        }
        
        .image-wrapper {
            grid-area: team-image;
            height: 100vh;
            width: 100%;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            background-image: url('../../assets/images/team.jpg');
            
            // @include device(m) {
            //     background-position: center top;
            //     background-image: url('../../assets/images/team-mobile.jpg');
            // }
        }

        .menu-wrapper {
            display: none;
            grid-area: menu;
            flex-direction: column;
            gap: 1.25em;
            align-items: stretch;
            justify-content: center;
            padding: 1em;
            .menu-item {
                overflow: hidden;
                .menu-link {
                    font-family: $base-font-family-bold;
                    font-size: 14.2vh;
                    font-weight: 900;
                    line-height: 0.72;
                    color: $on-base-light;
                    text-transform: uppercase;
                    text-decoration: none;
                    &:hover, &_active {
                        color: $primary;
                    }
                }
            }
        }

        @include device(m) {
            grid-template-columns: 1fr;
            grid-template-areas:
                'team-image'
                'menu'
                'content';
            .content-wrapper {
                padding: 110px 73px;
            }
        }

        @include device(l) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'team-image menu'
                'content content';

            .content-wrapper {
                padding: 10em;
            }
            .image-wrapper {
                background-position: center top;
            }
            .menu-wrapper {
                display: flex;
            }
        }
        @include device(xl, xxl, xxxl) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'team-image menu'
                'content content';

            .content-wrapper {
                padding: 5em;
            }
            .image-wrapper {
                background-position: center top;
            }
            .menu-wrapper {
                display: flex;
                .menu-item .menu-link {
                    font-size: 15vh;
                }
            }
        }
    }

    .video-section {
        position: relative;
        width: auto;
        height: auto;
        min-height: 420px;
        max-height: 480px;
        
        margin: 4em auto;
        margin-top: 0px;
        // iframe {
        //     min-height: 320px;
        //     height: auto !important;
        //     width: 100% !important;
        //     @include device(s, m) {
        //         min-height: 620px;
        //     }
        //     @include device(l, xl, xxl, xxxl) {
        //         min-height: 720px;
        //     }
        // }

        .video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%; 
            height: 100%; 
            z-index: 1;
            transform: translate(-50%, -50%);
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
        @include device(s) {
            min-height: 620px;
        }
        @include device(m) {
            min-height: 600px;
            max-width: 680px;
        }
        @include device(l) {
            min-height: 720px;
            max-width: 920px;
        }
        @include device(xl, xxl, xxxl) {
            min-height: 720px;
            max-width: 1190px;
        }
    }

    .scroll-button {
        display: none;
    }
}