@import 'src/styles/variables';

.jobs-wrapper {
    .title-wrapper {
        padding: 1em;
        .title {
            font-size: 8em;
            line-height: 0.72;
            font-weight: 900;
            font-family: $base-font-family-bold;
            text-align: center;
            color: $on-base-light;
            letter-spacing: -3px;
            @include device(s) {
                font-size: 9em;
            }
            @include device(m) {
                font-size: 9.4em;
            }
            @include device(l) {
                letter-spacing: -5px;
                font-size: 12.25em;
            }
            @include device(xl) {
                letter-spacing: -7px;
                font-size: 15em;
            }
            @include device(xxl) {
                letter-spacing: -7px;
                font-size: 14em;
            }
            @include device(xxxl) {
                letter-spacing: -8px;
                font-size: 17.8em;
            }
        }
    }

    .job-roles-filter-btn {
        text-align: center;
        padding: 60px 0 30px;
        user-select: none;
        -webkit-user-select: none;

        &.hide {
            display: none;
        }

        svg {
            cursor: pointer;
            &:hover {
                .btn-title {
                    fill: #282727;
                }
                .btn-bg {
                    fill: $primary;
                }
            }
        }

        @media screen and (min-width: 992px) {
            display: none !important;
        }
    }

    .job-roles-filter-wrapper {
        background: #B3B4B4;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: 991px) {
            &.m-view {
                padding: 25px 35px;
                width: 100%;
                height: 100vh;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999;
                color: #000000;
                overflow-y: auto;
            }
        }

        .job-roles-filter-close-btn {
            width: 20px;
            height: 20px;
            position: fixed;
            top: 1.5em;
            right: 1.5rem;
            z-index: 9;
            display: none;
            background-repeat: no-repeat !important;
            background-position: center;
            background: url('../../assets/images/BURGER_MENU_EXIT_BUTTON.svg');
            cursor: pointer;

            &.show {
                display: inline-block;
            }

            &:hover, &:active {
                background: url('../../assets/images/BURGER_MENU_EXIT_BUTTON_YELLOW.svg');
            }

            @media screen and (min-width: 992px) {
                display: none !important;
            }
        }

        .job-roles-filter-menu-wrapper {
            display: none;
            user-select: none;
            -webkit-user-select: none;

            &.show {
                display: block;
            }

            .title-wrapper {
                padding: 0 0 100px;
                display: flex;
                flex-direction: column;

                .title {
                    color: #282727;
                    text-align: center;
                    font-family: $base-font-family-bold;
                    font-size: 70px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 0.73; /* 68.5% 47.95px */
                    letter-spacing: -1.4px;
                }
                @media screen and (min-width: 992px) {
                    display: none !important;
                }
            }

            .sub-title {
                padding: 0 0 45px;
                color: #282727;
                font-family: $base-font-family;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.32px;
                text-transform: uppercase;
                @media screen and (min-width: 992px) {
                    display: none !important;
                }
            }

            .job-roles-filter-menu {
                display: grid;
                gap: 21px;

                .menu {
                    padding: 0 0 12px;
                    display: grid;
                    grid-template-columns: 1fr min-content;
                    border-bottom: 1px solid #000000;

                    &:first-child {
                        display: none;
                    }

                    .title {
                        text-transform: uppercase;
                        text-align: start;
                        color: #282727;
                        font-family: $base-font-family-black;
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 83%;
                        letter-spacing: 0.35px;
                    }

                    .check-box {
                        align-self: center;
                        width: 26px;
                        height: 26px;
                        svg {
                            width: inherit;
                            height: inherit;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

            }
            
            @media screen and (min-width: 992px) {
                display: block;
                margin-bottom: 50px;
                .job-roles-filter-menu {
                    padding: 14px 40px;
                    grid-template-columns: repeat(7, 1fr);
                    background: #B3B4B4;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
                    .menu {
                        padding: 0;
                        height: 13px;
                        display: flex;
                        gap: 4px;
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: flex-end;
                        border-bottom: 0;
    
                        .title {
                            align-self: flex-end;
                            color: #000;
                            font-family: $base-font-family;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 83%; /* 9.96px */
                            letter-spacing: 0.12px;
                            white-space: nowrap;
                        }
                        
                        .check-box {
                            width: 13px;
                            height: 16px;

                            svg {
                                width: inherit;
                                height: inherit;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }

                        &:first-child {
                            display: grid;

                            .title {
                                font-weight: 600;
                                text-shadow: 0 0 black;
                            }
                        }
                    }
                }
            }

            @media screen and (min-width: 992px) and (max-width: 1062px) {
                .job-roles-filter-menu {
                    padding: 14px 25px;
                    gap: 16px;
                }
            }
        }
    }

    .content-wrapper {
        margin-bottom: 50px;
        display: grid;
        grid-template-columns: 1fr;

        .content-item-wrapper {
            padding: 40px 30px;
            display: grid;
            gap: 18px;
            grid-template-columns: max-content 1fr;
            border-bottom: 1px dashed #B3B4B4;
            cursor: pointer;

            .logo {
                width: 59px;
                height: 59px;
                img {
                    width: inherit;
                    height: inherit;
                }
            }

            .title-wrapper {
                padding: 0;
                display: flex;
                gap: 6px;
                flex-direction: column;
                justify-content: center;

                .company-name {
                    text-transform: uppercase;
                    color: #FFF;
                    font-family: $base-font-family;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 83%;
                    letter-spacing: 0.18px;
                }
                .job-title {
                    text-transform: uppercase;
                    color: #FFF;
                    font-family: $base-font-family-black;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 83%;
                    letter-spacing: 0.18px;
                }
            }

            .job-details-wrapper {
                grid-area: 2/1/2/3;
                display: flex;
                gap: 10px;
                justify-content: space-between;

                .job-detail {
                    .label {
                        margin-bottom: 4px;
                        text-transform: uppercase;
                        color: #B3B4B4;
                        font-family: $base-font-family;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.24px;
                    }
                    .detail {
                        text-transform: uppercase;
                        color: #FFF;
                        font-family: $base-font-family;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.24px;
                    }
                }
            }

            &:hover {
                .title-wrapper {
                    .company-name {
                        color: $primary;
                    }
                    .job-title {
                        color: $primary;
                    }
                }
                .job-details-wrapper .job-detail .detail {
                    color: $primary;
                }
            }
        }

        .vertical-divider {
            display: none;
            position: absolute;
            top: 30px;
            left: 50%;
            height: calc(100% - 30px);
            border-left: 1px dashed #B3B4B4;
            @media screen and (min-width: 992px) {
                &.show {
                    display: block;
                }
            }
        }

        .loader-wrapper {
            grid-area: 1/1/2/3;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 5em;
                width: 5em;
            }
        }

        @media screen and (min-width: 992px) {
            grid-template-columns: 1fr 1fr;

            .content-item-wrapper {
                margin: 0 40px;
                gap: 25px;

                .logo {
                    grid-area: 1/1/3/2;
                    align-self: center;
                }

                .title-wrapper {
                    gap: 8px;
                    .company-name {
                        text-transform: uppercase;
                        color: #FFF;
                        font-family: $base-font-family;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 83%; /* 9.96px */
                        letter-spacing: 0.12px;
                    }

                    .job-title {
                        text-transform: uppercase;
                        color: #FFF;
                        font-family: $base-font-family-black;
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 83%;
                        letter-spacing: 0.35px;
                    }
                }

                .job-details-wrapper {
                    grid-area: unset;

                    .job-detail {
                        .label {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .not-found-msg {
        text-align: center;
        font-size: 24px;

        @include device(l, xl, xxl) {
            padding-top: 40px;
        }
    }


    .view-job-wrapper {
        padding: 30px 0px 50px;

        .logo {
            margin: 28px 20px 28px;
            padding: 0;
            img {
                width: 70px;
                height: 70px;
            }
        }

        .job-details {
            padding: 0 20px 50px;
            border-bottom: 1px dashed #B3B4B4;;

            .company-name {
                padding: 0 0 25px;
                text-transform: uppercase;
                color: #FFF;
                font-family: $base-font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 76.5%; /* 15.3px */
                letter-spacing: 0.2px;
            }

            .job-title {
                padding: 0 0 25px;
                text-transform: uppercase;
                color: #FFF;
                font-family: $base-font-family-black;
                font-size: 35px;
                font-style: normal;
                font-weight: 800;
                line-height: 76.5%;
                letter-spacing: 0.35px;
            }

            .job-basic-info {
                max-width: 795px;
                display: flex;
                gap: 10px;
                justify-content: space-between;

                .label {
                    margin-bottom: 4px;
                    text-transform: uppercase;
                    color: #B3B4B4;
                    font-family: $base-font-family;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
                .detail {
                    text-transform: uppercase;
                    color: #FFF;
                    font-family: $base-font-family;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
            }
        }

        .vertical-logo {
            display: none;
            background-image: url('../../assets/images/LOGO_VERTICAL_PATTERN.png');
            background-repeat: repeat-y;
        }

        .job-description {
            padding: 51px 20px 50px;
            
            .title {
                text-transform: uppercase;
                margin-bottom: 27px;
                color: #FFF;
                font-family: $base-font-family;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 83%; /* 16.6px */
                letter-spacing: 0.2px;
            }

            .description {
                max-width: 795px;
                color: #FFF;
                font-family: $base-font-family;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.24px;
                white-space: pre-line;
            }
        }

        @media screen and (min-width: 992px) {
            padding: 85px 60px 0px 0;
            display: grid;
            gap: 0 120px;
            grid-template-columns: 1fr 2.5fr;

            .logo {
                margin: 0;
                padding-left: 60px;
                display: flex;
                justify-content: flex-end;
                img {
                    width: 224px;
                    height: 224px;
                }
            }

            .job-details {
                padding: 0 0px 47px;

                .company-name {
                    padding: 9px 0 10px;
                    font-size: 40px;
                    letter-spacing: 0.4px;
                }
                .job-title {
                    padding-bottom: 50px;
                    font-size: 70px;
                    letter-spacing: 0.7px;
                }

                .job-basic-info {
                    .label {
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                    }
                    .detail {
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                    }
                }
            }

            .vertical-logo {
                margin-top: -40px;
                display: block;
            }

            .job-description {
                padding: 51px 0px 20px;
                grid-area: 2/2;

                .title {
                    margin-bottom: 50px;
                    font-size: 45px;
                    letter-spacing: 0.45px;
                }

                .description {
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 0.4px;
                }
            }
        }
    }
    
}