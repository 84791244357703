@import 'src/styles/variables';

.about-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto auto;
    grid-template-areas: 
        'title'
        'video'
        'content';
    padding-bottom: 1em;
    // min-height: 100vh;

    @include device(l) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 180px auto;
        grid-template-areas: 
            'video title'
            'video content';
    }
    @include device(xl) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 220px auto;
        grid-template-areas: 
            'video title'
            'video content';
    }
    @include device(xxl) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 260px auto;
        grid-template-areas: 
            'video title'
            'video content';
    }
    @include device(xxxl) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 300px auto;
        grid-template-areas: 
            'video title'
            'video content';
    }

    .title-wrapper {
        grid-area: title;
        padding: 1em;
        .title {
            font-size: 8em;
            line-height: 0.72;
            font-weight: 900;
            font-family: $base-font-family-bold;
            text-align: center;
            color: $on-base-light;
            letter-spacing: -7px;
            @include device(s, m) {
                font-size: 14em;
                line-height: 0.72;
            }
            @include device(l) {
                font-size: 12em;
                line-height: 0.72;
            }
            @include device(xl) {
                font-size: 15em;
                line-height: 0.72;
            }
            @include device(xxl) {
                font-size: 14em;
                line-height: 0.72;
            }
            @include device(xxxl) {
                font-size: 18em;
                line-height: 0.72;
            }
        }
    }
    .video-wrapper {
        grid-area: video;
        height: auto;
        max-height: 100vh;
        width: 100%;
        .video {
            height: inherit;
            width: inherit;
            max-height: inherit;
            object-fit: cover;
            object-position: top center;

        }
        @include device(s, m) {
            height: auto;
            max-height: 100vh;
            width: 100%;
        }
        @include device(l) {
            height: calc(100vh - 169px);
            width: 100%;
        }
        @include device(xl) {
            height: calc(100vh - 169px);
            width: 100%;
        }
        @include device(xxl) {
            height: calc(100vh - 169px);
            width: 100%;
        }
        @include device(xxxl) {
            height: calc(100vh - 169px);
            width: 100%;
        }
    }

    .content-wrapper {
        grid-area: content;
        padding: 3.25em 2.25em;
        .content {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            height: 100%;
            width: 100%;

            .content-container {
                // margin: auto 0px;
                .title {
                    font-family: $base-font-family;
                    font-size: 1.25em;
                    font-weight: 900;
                    line-height: 1;
                    margin-bottom: 2em;
                    color: $on-base;
                }
            }
            .details-wrapper {
                display: grid;
                gap: 1.1em;
                grid-template-columns: 1fr;
                overflow: auto;
                height: unset;
                padding-right: 5px;
                text-align: center;
                @include device(l, xl, xxl, xxxl) {
                    height: 35vh;
                    text-align: left;
                }
                &::-webkit-scrollbar {
                    width: 2px !important;
                    height: 0px !important;
                  }
                  &::-webkit-scrollbar-track {
                    background-color: transparent;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: $on-base-light;
                  }
                .details {
                    font-family: $base-font-family;
                    font-size: 0.92em;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $on-base;
                }
                .company-list {
                    padding-left: 3em;
                    text-align: left;
                    .company-item {
                        font-size: 1.1em;
                        font-weight: 500;
                        line-height: 1;
                        color: $on-base;
                        span {
                            padding-inline-start: 0.62em;
                        }
                    }
                }

            }

            .link-wrapper {
                height: 100px;
                padding: 2em;
                font-family: $base-font-family;
                font-size: 0.92em;
                font-weight: 500;
                line-height: 1.2;
                color: $on-base;
                text-align: center;
                .link {
                    font-size: 1.25em;
                    font-weight: 900;
                    line-height: 1;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $on-base;
                    padding: 0em 0.4em;
                    &:hover {
                        color: $primary;
                    }
                }
                @include device(xl, xxl, xxxl) {
                    display: none;
                }
            }
        }

        @include device(s, m) {
            padding: 3.25em 2.25em;
        }
        @include device(l, xl, xxl, xxxl) {
            padding: 2.25em 5.25em 2.25em 2.25em;
        }
    }
}


