$base-100: #5C5C5C;
$base-200: #525252;
$base-300: #474747;
$base-400: #3D3D3D;
$base: #333333;
$base-600: #292929;
$base-700: #1F1F1F;
$base-800: #141414;
$base-900: #0A0A0A;
$on-base: #FFFFFF;
$on-base-light: #B1B2B2;
$on-base-dark: #A2A4A4;

$primary-100: #EDFF85;
$primary-200: #E3FF47;
$primary-300: #E0FF33;
$primary-400: #DDFF1F;
$primary: #DAFF00;
$primary-600: #D0F500;
$primary-700: #BFE000;
$primary-800: #ADCC00;
$primary-900: #9CB800;
$on-primary: #141414;
$on-primary-light: #000000;
$on-primary-dark: #333333;

// $error-100: #F99F9F;
// $error-200: #F88C8C;
// $error-300: #F67979;
// $error-400: #F56666;
// $error: #F34848;
// $error-600: #F33F3F;
// $error-700: #F22C2C;
// $error-800: #F60F0F;
// $error-900: #D30D0D;
// $on-error: #FFFFFF;
// $on-error-light: #E5E5E5;
// $on-error-dark: #D6D6D6;