.app-page {
    display: block;
    height: auto;
    width: 100%;

    &__heading {
        display: block;
        font-family: $base-font-family-bold;
        font-size: 25vw;
        line-height: 1em;
        color: $on-base-light;
        margin-bottom: 0.2em;
        text-align: center;
    }

    &__content {
        display: block;
        padding: 4em 1em;
    }
}