@font-face {
    font-family: adihaus-din;
    src: url(../assets/fonts/AdihausDIN-Regular.ttf);
}
@font-face {
    font-family: adineue-black;
    src: url(../assets/fonts/adineueCHOP-Black.otf);
}
@font-face {
    font-family: adineue-bold;
    src: url(../assets/fonts/adineueCHOP-Bold.otf);
}

$base-font-family: 'adihaus-din', 'sans-serif';
$base-font-family-bold: 'adineue-black', 'sans-serif';
$base-font-family-black: 'adineue-bold', 'sans-serif';
$base-line-height: 1.2;