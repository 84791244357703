@import 'src/styles/variables';

.terms-wrapper {

    .title-wrapper {
        padding: 1em;
        .title {
            font-size: 5em;
            line-height: 0.72;
            font-weight: 900;
            font-family: $base-font-family-bold;
            text-align: center;
            color: $on-base-light;
            letter-spacing: -1px;
            @include device(s) {
                font-size: 8em;
                letter-spacing: -3px;
            }
            @include device(m) {
                font-size: 9em;
                letter-spacing: -3px;
            }
            @include device(l) {
                font-size: 11em;
                letter-spacing: -5px;
            }
            @include device(xl) {
                letter-spacing: -5px;
                font-size: 11em;
            }
            @include device(xxl) {
                letter-spacing: -7px;
                font-size: 11em;
            }
            @include device(xxxl) {
                letter-spacing: -7px;
                font-size: 12em;
            }
        }
    }
    
    .content-wrapper {
        padding: 5em 3.25em;
        grid-area: content;
        text-align: center;
        
        .content-container {
            margin-bottom: 2.72em;
            .title {
                font-size: 1.55em;
                font-weight: 900;
                line-height: 1;
                color: $on-base;
                text-transform: uppercase;
                margin-bottom: 1.25em;
            }
            .content {
                font-size: 1.1em;
                font-weight: 500;
                line-height: 1.2;
                color: $on-base;
                &:not(:last-of-type) {
                    margin-bottom: 0.25em;
                }
                &.content-title {
                    font-weight: 700;
                    margin-top: 0.62em;
                    text-transform: uppercase;
                }
            }
        }
        @include device(s) {
            padding: 6em 7em;
        }
        @include device(m) {
            padding: 7em 8em;
        }
        @include device(l) {
            padding: 7em 11em;
        }
        @include device(xl) {
            padding: 7em 12em;
        }
        @include device(xxl, xxxl) {
            padding: 7em 14em;
        }
    }
}

ul {
    list-style-type: disc;
    padding: 1em 3em;
    text-align: left;
}