@import 'src/styles/variables';

.header-wrapper {
    .brand-wrapper {
        position: fixed;
        top: 0em;
        left: 0.25em;
        height: 6.8em;
        width: auto;
        z-index: 99;
        cursor: pointer;
        .brand {
            height: inherit;
            width: inherit;
        }
        @include device(s, m) {
            height: 7.8em;
            left: 1em;
        }
        @include device(l, xl) {
            height: 7.8em;
            left: 0.72em;
        }
        @include device(xxl, xxxl) {
            height: 8em;
            left: 0.92em;
            top: -0.5em;
        }
    }

    .navigation-wrapper {
        position: relative;
        z-index: 999;
        .navigation-toggler {
            position: fixed;
            top: 1em;
            right: 2em;
            display: grid;
            place-content: center;
            height: auto;
            min-height: 25px;
            width: auto;
            min-width: 25px;
            user-select: none;
            z-index: 11;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            // padding: 20px;
            div {
                background-repeat: no-repeat;
                width: 2em;
                height: 1.5em;
            }
            &:active.collapsed > div {
                background: url('../../assets/images/BURGER_MENU_YELLOW.svg');
            }
            &.collapsed > div {
                background: url('../../assets/images/BURGER_MENU.svg');
            }
            &:active.expanded > div {
                background-repeat: no-repeat !important;
                background-position: center;
                background: url('../../assets/images/BURGER_MENU_EXIT_BUTTON_YELLOW.svg');
            }
            &.expanded > div {
                background-repeat: no-repeat !important;
                background-position: center;
                background: url('../../assets/images/BURGER_MENU_EXIT_BUTTON.svg');
            }

            div.close-icon {
                margin-top: 12px;
                width: 23px;
                height: 23px;
                display: inline-block;
                background-repeat: no-repeat !important;
                background-position: center;
                background: url('../../assets/images/BURGER_MENU_EXIT_GRAY_BUTTON.svg');
                &:hover {
                    background: url('../../assets/images/BURGER_MENU_EXIT_BUTTON_YELLOW.svg');
                }

                @media screen and (min-width: 992px) {
                    margin-top: 0;
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .navigation-container {
            position: fixed;
            display: block;
            inset: 0 0 0 0;
            background-color: $on-base-light;
            padding: 2em;
            overflow: auto;
            z-index: 9;
            .nav-links-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                .nav-link {
                    display: block;
                    font-family: $base-font-family-bold;
                    font-size: 4.25em;
                    line-height: 0.75;
                    text-decoration: none;
                    text-align: center;
                    color: $on-primary;
                    margin-bottom: 1rem;
                    &:hover, &_active {
                        color: $primary;
                    }
                }
            }
            &.collapsed {
                display: none;
            }
            
            @include device(xs) {
                display: block;
                margin: auto;
                padding: min(15vh, 8em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 5.2em;
                }
            }
            @media only screen and (max-width: 360px) {
                display: block;
                margin: auto;
                padding: min(15vh, 8em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 4.25em;
                }
            }
            @include device(s) {
                display: block;
                margin: auto;
                padding: min(15vh, 8em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 13.5vh;
                }
            }
            @include device(m) {
                display: block;
                margin: auto;
                padding: min(10vh, 4em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 14.2vh;
                }
            }
            @include device(l) {
                display: block;
                margin: auto;
                padding: min(10vh, 5em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 14.2vh;
                }
            }
            @include device(xl) {
                display: block;
                margin: auto;
                padding: min(10vh, 5em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 8vh;
                }
            }
            @include device(xxl){
                display: block;
                margin: auto;
                padding: min(10vh, 6em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 9.1vh;
                }
            }
            @include device(xxl,xxxl) {
                display: block;
                margin: auto;
                padding: min(10vh, 6em) 2em;
                .nav-links-wrapper .nav-link {
                    font-size: 9vh;
                }
            }

        }

        @include device(xs, s, m) {
            .navigation-toggler {
                position: fixed;
                top: 1em;
                right: calc(2em + 17px);
                cursor: pointer;
                span {
                    position: relative;
                    display: block;
                    height: 2px;
                    width: 20px;
                    background-color: $on-primary;
                    transform: rotate(45deg);
                    &::after {
                        content: '';
                        position: absolute;
                        display: inherit;
                        height: 2px;
                        width: 20px;
                        top: 0px;
                        background-color: inherit;
                        transform: rotate(95deg);
                    }
                    &::before {
                        display: none;
                    }
                }
                &.collapsed {
                    right: calc(1em);
                    span {
                        transform: none;
                        &::after {
                            top: 6px;
                            transform: none;
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            display: inherit;
                            height: 2px;
                            width: 20px;
                            top: -6px;
                            background-color: inherit;
                        }
                    }
                }
            }
        }
        @include device(l, xl, xxl, xxxl) {
            .navigation-toggler {
                div {
                    top: 0.85em;
                }
            }
        }
        @include device(xl,xxl,xxxl) {
            .navigation-container{
                inset: unset;
                right: 0;
                width: 40vw;
                height: 100vh;
                .nav-links-wrapper{
                    width: 100%;
                    display: inline;
                }
            }
        }
        @include device(m, s, xs) {
            .navigation-toggler {
                div {
                    width: 1.5em;
                    height: 1.075em;
                }
            }
        }
    }
}