@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import 'src/styles/variables';

.footer-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    height: auto;
    width: 100%;

    .brand-partners-wrapper {
      width: calc(100% - 4em);
      height: auto;
      min-height: 150px;
      margin: 0.5em 2em;
      background-image: url('../../assets/images/LOGO_STRIP_MOBILE-01.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include device(l, xl, xxl, xxxl) {
        min-height: 76px;
        background-image: url('../../assets/images/LOGO_STRIP_DESKTOP.svg');
        background-size: contain;
      }
    }

    .navigation-wrapper {
        position: relative;
        padding: 0.875em 1em 1em;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-around;
        color: $on-primary;
        background-color: $primary;

        .nav-link {
            font-size: 18px;
            font-weight: 900;
            line-height: 1;
            color: $on-primary;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: -0.18px;
        }

        .vertical-divider {
          align-self: stretch;
          border-right: 2px solid $on-primary;
        }

        @include device(xs) {
          .nav-link {
            font-size: 12px;
          }
        }
        @include device(s) {
          .nav-link {
            font-size: 12px;
          }
          gap: 12px;
        }
        @include device(m) {
          .nav-link {
            font-size: 16px;
          }
        }
    }

    .footer-pattern {
      height: 169px;
      width: 100%;
      display: block;
      background-image: url('../../assets/images/footer-pattern-mobile.svg');
      background-repeat: repeat;
      background-size: cover;
      background-position: bottom right;

      @include device(l, xl, xxl, xxxl) {
        background-image: url('../../assets/images/footer-pattern.svg');
      }
    }

    .footer-links-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1em;
      padding: 0.72em 2em;
      .footer-link {
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        color: $on-base-light;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: -0.18px;
        &:hover, &_active {
          color: $primary;
        }
      }
    }
}

// .carousel-container {
//     height: auto;
//     max-height: 170px;
//     .slide {
//       display: grid !important;
//       place-content: center;
//       width: 8em;
//       height: 100%;
//       margin: auto 0px;

//       img {
//         height: inherit;
//         max-height: 120px;
//         width: auto;
//         transition: all 250ms ease-in;
//         overflow: hidden;
//       }
//     }
// }

// .slick-list {
//     outline: none;
//     .slick-slide {
//     }
// }