@import 'src/styles/variables';

.app-wrapper {
    position: relative;
    display: block;
    height: auto;
    min-height: calc(100vh);
    width: 100%;
    padding-bottom: 375px;
    
    /** adjusting bottom padding for footer */
    @include device(l, xl) {
        padding-bottom: 300px;
    }
    @include device(xxl, xxxl) {
        padding-bottom: 300px;
    }
}